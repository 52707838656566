import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useSlug, useTranslationLink } from 'src/utils';
import { SEO } from 'src/components/common';
import { Content } from 'src/components/home';
import { GetHomeQuery } from 'graphql-types';
import { DefaultPageContextProps } from 'custom-types';

type Props = PageProps<GetHomeQuery, DefaultPageContextProps>;

export const query = graphql`
  query GetHome($lang: String!, $latestSeasonId: String) {
    site {
      siteMetadata {
        titleCustom {
          ja {
            home
          }
          en {
            home
          }
        }
        description {
          ja {
            home
          }
          en {
            home
          }
        }
      }
    }
    prismic {
      ...HomeContent
    }
  }
`;

type UseSEOProps = Pick<DefaultPageContextProps, 'langKey'> & {
  site: GetHomeQuery['site'];
};
function useSEO({ site, langKey }: UseSEOProps) {
  return React.useMemo(() => {
    const title =
      langKey === 'ja'
        ? site?.siteMetadata?.titleCustom?.ja?.home
        : site?.siteMetadata?.titleCustom?.en?.home;
    const description =
      langKey === 'ja'
        ? site?.siteMetadata?.description?.ja?.home
        : site?.siteMetadata?.description?.en?.home;
    return {
      title,
      description,
    };
  }, [site, langKey]);
}

const IndexPage: React.FC<Props> = ({ data: { site, prismic } }: Props) => {
  const { slug, langKey } = useSlug();
  useTranslationLink({ slug, langKey });
  const seo = useSEO({ site, langKey });
  return (
    <>
      <SEO {...seo} />
      <Content {...prismic} />
    </>
  );
};

export default IndexPage;
