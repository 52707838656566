import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import { htmlSerializer } from 'src/utils';
import { MenuButton } from 'src/components/common';
import { HomeEntryFragment } from 'graphql-types';

type Props = HomeEntryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeEntry on PRISMIC_Home {
    content
  }
`;

const useHasHiddenForPC = () => {
  const [hasHiddenForPC, setHasHiddenPC] = React.useState(false);
  const close = React.useCallback(() => {
    setHasHiddenPC(true);
  }, []);
  return { hasHiddenForPC, close };
};

export const Entry: React.FC<Props> = ({ className, content }: Props) => {
  const { hasHiddenForPC, close } = useHasHiddenForPC();

  return (
    <Wrapper className={`${className || ''} ${hasHiddenForPC ? 'hidden' : ''}`}>
      <RichText render={content} htmlSerializer={htmlSerializer} />
      <CloseButtonForMobile
        onClick={close}
        aria-hidden={true}
        isPressed={true}
      />
    </Wrapper>
  );
};

const CloseButtonForMobile = styled(MenuButton)`
  display: none;
  ${media.ipadHorizontalOrMore`
    display: block;
    margin-top: 10px;
  `}
`;

const Wrapper = styled.div`
  background-color: white;
  border: ${({ theme }) => theme.border};
  padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-top: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  ${media.lessThanIpadHorizontal`
    border-bottom: none;
  `}
  ${media.ipadHorizontalOrMore`
    border-top: none;
    padding-bottom: 15px;
    &.hidden {
      display: none;
    }
  `}
`;

export default Entry;
