import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { media } from 'src/styles';
import { RichText } from 'prismic-reactjs';
import { useSlug } from 'src/utils';
import { WithoutMargin, HoverEffect } from 'src/components/common';
import { Anchor, Img } from 'src/components/common/atoms';
import {
  HomeBuildFragment,
  HomeBirthFragment,
  HomeSeedFragment,
} from 'graphql-types';

type Props = {
  className?: string;
  products: HomeBuildFragment[] | HomeBirthFragment[] | HomeSeedFragment[];
};

export const homeProductsFragment = graphql`
  fragment HomeBuild on PRISMIC_Build {
    _meta {
      type
      uid
      id
    }
    title
    season {
      ... on PRISMIC_Season {
        _meta {
          uid
        }
      }
    }
    images {
      image
      imageSharp {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
  fragment HomeBirth on PRISMIC_Birth {
    _meta {
      type
      uid
      id
    }
    title
    season {
      ... on PRISMIC_Season {
        _meta {
          uid
        }
      }
    }
    images {
      image
      imageSharp {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
  fragment HomeSeed on PRISMIC_Seed {
    _meta {
      type
      uid
      id
    }
    title
    season {
      ... on PRISMIC_Season {
        _meta {
          uid
        }
      }
    }
    images {
      image
      imageSharp {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export const Products: React.FC<Props> = ({ className, products }: Props) => {
  const brandName = products[0]._meta.type;
  const season = products[0].season?._meta.uid;
  const { langKey } = useSlug();
  return (
    <div className={className || ''}>
      <WithoutMargin>
        <Images>
          {products.map((product) => {
            const fluid =
              product.images?.[0]?.imageSharp?.childImageSharp?.fluid;
            const title = product?.title
              ? RichText.asText(product.title)
              : null;
            if (!fluid) {
              return null;
            }
            return (
              <HoverEffect key={product._meta.id}>
                <Anchor
                  to={`${
                    langKey !== 'ja' ? `/${langKey}` : ''
                  }/${brandName}/${season}/${product._meta.uid}/`}
                >
                  <Img
                    fluid={fluid}
                    sizes="(min-width: 1024px) calc((100vw / 3) / 2), calc(100vw / 2)"
                    alt={
                      product.images?.[0]?.image?.alt || title
                        ? `Photo of ${title}`
                        : ''
                    }
                  />
                </Anchor>
              </HoverEffect>
            );
          })}
        </Images>
      </WithoutMargin>
      {brandName && season && (
        <StyledAnchorLookbook to={`/${brandName}/${season}/`}>
          Lookbook
        </StyledAnchorLookbook>
      )}
    </div>
  );
};

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const StyledAnchorLookbook = styled(Anchor)`
  font-size: ${({ theme }) => theme.font.size.xxlarge};
  display: inline-block;
  margin-top: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  &::before {
    content: '>';
  }
  &:hover,
  &.focus-visible {
    text-decoration: underline;
  }
  &:focus-visible {
    text-decoration: underline;
  }
`;

export default Products;
