import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useIsMobile, htmlSerializer } from 'src/utils';
import { WithoutMargin, Video } from 'src/components/common';
import { Products } from './products';
import {
  HomeBrandFragment,
  HomeBuildFragment,
  HomeBirthFragment,
  HomeSeedFragment,
} from 'graphql-types';

type Props = HomeBrandFragment & {
  className?: string;
  products?: HomeBuildFragment[] | HomeBirthFragment[] | HomeSeedFragment[];
};

export const fragment = graphql`
  fragment HomeBrand on PRISMIC_Brand {
    _meta {
      uid
    }
    title
    logo_type_image
    logo_type_imageSharp {
      publicURL
    }
    video_id
    excerpt
  }
`;

export const Brand: React.FC<Props> = ({
  className,
  _meta,
  title,
  logo_type_imageSharp,
  video_id,
  excerpt,
  products,
}: Props) => {
  const isMobile = useIsMobile();
  const { uid: brandName } = _meta;
  return (
    <Wrapper className={`${className || ''} ${brandName || ''}`}>
      <Header>
        {logo_type_imageSharp?.publicURL && (
          <LogoWrapper>
            <img
              src={logo_type_imageSharp.publicURL}
              alt={title ? `${RichText.asText(title)} logo type` : ''}
            />
          </LogoWrapper>
        )}
        {!isMobile && video_id && (
          <WithoutMargin>
            <StyledVideo videoId={video_id} isFit={true} />
          </WithoutMargin>
        )}
      </Header>
      <Content>
        <RichText render={excerpt} htmlSerializer={htmlSerializer} />
      </Content>
      {products?.length && <StyledProducts products={products} />}
    </Wrapper>
  );
};

const StyledVideo = styled(Video)`
  height: 70vh;
`;

const LogoWrapper = styled.h2`
  height: 24px;
  img {
    height: 100%;
    width: auto;
  }
  ${media.ipadHorizontalOrMore`
    margin-top: ${({ theme }) => theme.structure.margin.small}px;
  `}
`;

const Header = styled.header`
  ${media.ipadHorizontalOrMore`
    display: flex;
    flex-direction: column-reverse;
  `}
`;

const Content = styled.div`
  margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
`;

const StyledProducts = styled(Products)`
  margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
`;

const Wrapper = styled.section`
  &.build {
    color: ${({ theme }) => theme.colors.build};
  }
  &.birth {
    color: ${({ theme }) => theme.colors.birth};
  }
  &.seed {
    color: ${({ theme }) => theme.colors.seed};
  }
`;

export default Brand;
