import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useIsMobile, nonNullableFilter } from 'src/utils';
import { ContentColumn, Video } from 'src/components/common';
import { Entry } from './entry';
import { Brand } from './brand';
import { HomeContentFragment } from 'graphql-types';
import { Unpacked } from 'custom-types';

type Props = HomeContentFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeContent on PRISMIC {
    home(lang: $lang, uid: "home") {
      ...HomeEntry
    }
    allBrands(lang: $lang, sortBy: date_DESC) {
      edges {
        node {
          _meta {
            uid
          }
          ...HomeBrand
        }
      }
    }
    allBuilds(
      lang: $lang
      sortBy: date_DESC
      where: { season: $latestSeasonId }
    ) {
      edges {
        node {
          _meta {
            type
          }
          ...HomeBuild
        }
      }
    }
    allBirths(
      lang: $lang
      sortBy: date_DESC
      where: { season: $latestSeasonId }
    ) {
      edges {
        node {
          _meta {
            type
          }
          ...HomeBirth
        }
      }
    }
    allSeeds(
      lang: $lang
      sortBy: date_DESC
      where: { season: $latestSeasonId }
    ) {
      edges {
        node {
          _meta {
            type
          }
          ...HomeSeed
        }
      }
    }
  }
`;

function useBrands(
  brandEdges: HomeContentFragment['allBrands']['edges'],
  buildEdges: HomeContentFragment['allBuilds']['edges'],
  birthEdges: HomeContentFragment['allBirths']['edges'],
  seedEdges: HomeContentFragment['allSeeds']['edges']
) {
  return React.useMemo(() => {
    if (!brandEdges) {
      return null;
    }
    const productEdges =
      buildEdges && birthEdges && seedEdges
        ? [...buildEdges, ...birthEdges, ...seedEdges]
        : birthEdges ?? seedEdges ?? [];

    const customBrands = brandEdges
      .filter(nonNullableFilter)
      .map(({ node }) => {
        const products =
          productEdges && productEdges.length
            ? productEdges
                .filter(nonNullableFilter)
                .filter(
                  ({ node: brandNode }) =>
                    brandNode._meta.type === node._meta.uid
                )
                .map(({ node }) => ({ ...node }))
            : null;

        if (products?.length) {
          return { ...node, ...{ products } };
        } else {
          return { ...node };
        }
      });
    return customBrands.reduce<{
      [key: string]: Unpacked<typeof customBrands>;
    }>((prev, current) => {
      if (!current._meta.uid) {
        return prev;
      }
      return {
        ...prev,
        [current._meta.uid]: current,
      };
    }, {});
  }, [brandEdges, buildEdges, birthEdges, seedEdges]);
}

export const Content: React.FC<Props> = ({
  className,
  home,
  allBrands,
  allBuilds,
  allBirths,
  allSeeds,
}: Props) => {
  const isMobile = useIsMobile();
  const brands = useBrands(
    allBrands.edges,
    allBuilds.edges,
    allBirths.edges,
    allSeeds.edges
  );
  return (
    <Wrapper className={className || ''}>
      {isMobile && brands && (
        <MobileVideos>
          {brands?.build?.video_id && (
            <Video videoId={brands.build.video_id} isFit={true} />
          )}
          {brands?.birth?.video_id && (
            <Video videoId={brands.birth.video_id} isFit={true} />
          )}
          {brands?.seed?.video_id && (
            <Video videoId={brands.seed.video_id} isFit={true} />
          )}
        </MobileVideos>
      )}
      <StyledEntry {...home} />
      <ContentColumn
        items={[
          {
            component: brands?.build ? <Brand {...brands.build} /> : null,
            order: 1,
            hasMarginTop: false,
          },
          {
            component: brands?.birth ? <Brand {...brands.birth} /> : null,
            order: 2,
            hasMarginTop: false,
          },
          {
            component: brands?.seed ? <Brand {...brands.seed} /> : null,
            order: 3,
            hasMarginTop: false,
          },
        ]}
      />
    </Wrapper>
  );
};

const StyledEntry = styled(Entry)`
  ${media.ipadHorizontalOrMore`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 40%;
    max-width: 430px;
  `}
`;

const MobileVideos = styled.div`
  ${media.lessThanIpadHorizontal`
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${({ theme }) => theme.headerHeight.sp}px);
  `}
`;

const Wrapper = styled.div`
  position: relative;
`;

export default Content;
